import * as React from "react";
import { useForm } from "react-hook-form";
import { api } from "../../utils/axios";

interface OptionProps {
  colorOption: ColorType[];
  item: { id: number; name: string; image: string };
}

interface NewDataType {
  option: string;
  item_id: number;
  flag: string;
}

interface ColorType {
  id: number;
  select_id: number;
  name: string;
}

const ColorEditOption: React.FC<OptionProps> = ({ colorOption, item }) => {
  const {
    formState: { errors },
  } = useForm();

  const [sendData, setData] = React.useState({
    option: "",
    item_id: 0,
    flag: "",
  });

  const [colorOptions, setColorOption] = React.useState(colorOption);
  const [inputText, setInputText] = React.useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputText(e.target.value);
    const newSendData: NewDataType = {
      option: e.target.value,
      item_id: item.id,
      flag: "color",
    };
    setData(newSendData);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputText) {
      return;
    }
    api
      .post("/api/item_option/color", {
        sendData,
      })
      .then((res) => {
        setColorOption([res.data.option, ...colorOptions]);
        setInputText("");
      })
      .catch(() => {
        alert("データの追加に失敗しました");
      });
  };

  //削除
  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement>,
    colorSelect_id: number,
    color_id: number
  ) => {
    e.preventDefault();
    api
      .delete(`/api/item_option/color/${colorSelect_id}`)
      .then((res) => {
        const set_colorOptions = colorOptions.filter(
          (color) => color.id !== color_id
        );
        setColorOption(set_colorOptions);
      })
      .catch(() => {
        alert("データの削除に失敗しました");
      });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="admin-form">
      <h4>カラー編集</h4>
      {Object.values(colorOptions).map((color) => {
        return (
          <div className="adminOption-list">
            <input
              className="adminOptionListInput"
              value={color.name}
              type="text"
            />
            {errors.name && (
              <span className="text-danger">名前は1文字以上</span>
            )}
            <button
              className="adminOptionListBtn  --delete"
              onClick={(e) => handleDelete(e, color.select_id, color.id)}
            >
              削除
            </button>
          </div>
        );
      })}
      <div className="adminOption-list">
        <input
          type="text"
          onChange={(e) => handleChange(e)}
          value={inputText}
          className="adminOptionListInput"
        />
        <input
          type="submit"
          value="作成"
          className="adminOptionListBtn  --create"
          name="create"
        />
      </div>
    </form>
  );
};

export default ColorEditOption;
