import * as React from "react";

interface Props {
  greeting: string;
}

// class HelloWorld extends React.Component<Props> {

//   render () {
//     return (
//       <React.Fragment>
//         Greeting: {this.props.greeting}
//       </React.Fragment>
//     );
//   }
// }

const HelloWorld: React.FC<Props> = ({ greeting }) => {
  return <div>{greeting}</div>;
};

export default HelloWorld;
