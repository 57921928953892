import axios from "axios";

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://www.pairl.jp"
      : "http://localhost:3000",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "x-csrf-token": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  },
});
