import * as React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { api } from "../../utils/axios";

interface OptionProps {
  sizeOption: SizeType[];
  item: { id: number; name: string; image: string };
}

interface NewDataType {
  category: string;
  gender: string;
  item_id: number;
  flag: string;
}

interface SizeType {
  id: number;
  select_id: number;
  is_stock: boolean;
  category: string;
  gender: string;
}

const SizeEditOption: React.FC<OptionProps> = ({ sizeOption, item }) => {
  const {
    formState: { errors },
  } = useForm();

  const [sendData, setData] = React.useState({
    category: "",
    gender: "",
    item_id: 0,
    flag: "",
  });

  const [sizeOptions, setsizeOption] = React.useState(sizeOption);
  const [inputText, setInputText] = React.useState("");
  const [checkBoxValue, setCheckBoxValue] = React.useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputText(e.target.value);

    if (sendData.gender) {
      const newSendData: NewDataType = {
        ...sendData,
        category: e.target.value,
      };
      setData(newSendData);
    } else {
      const newSendData: NewDataType = {
        category: e.target.value,
        gender: "",
        item_id: item.id,
        flag: "size",
      };
      setData(newSendData);
    }
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCheckBoxValue(e.target.value);

    if (sendData.category) {
      const newSendData: NewDataType = { ...sendData, gender: e.target.value };
      setData(newSendData);
    } else {
      const newSendData: NewDataType = {
        category: "",
        gender: e.target.value,
        item_id: item.id,
        flag: "size",
      };
      setData(newSendData);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputText) {
      return;
    }
    api
      .post("/api/item_option/size", {
        sendData,
      })
      .then((res) => {
        setsizeOption([res.data.option, ...sizeOptions]);
        setInputText("");
      })
      .catch(() => {
        alert(
          "データの追加に失敗しました。以下のフォーマットで送信してください。「カテゴリ」・「ジェンダー」"
        );
      });
  };

  const handleUpdateStock = async (
    e: React.MouseEvent<HTMLButtonElement>,
    sizeSelect_id: number,
    size_id: number
  ) => {
    e.preventDefault();
    api
      .patch(`/api/item_option/size/${sizeSelect_id}`)
      .then((res) => {
        const set_sizeOptions = sizeOptions
        window.location.reload()
        setsizeOption(set_sizeOptions);
      })
      .catch(() => {
        alert("データの削除に失敗しました");
      });
  };
  

  //削除
  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement>,
    sizeSelect_id: number,
    size_id: number
  ) => {
    e.preventDefault();
    api
      .delete(`/api/item_option/size/${sizeSelect_id}`)
      .then((res) => {
        const set_sizeOptions = sizeOptions.filter(
          (size) => size.id !== size_id
        );
        setsizeOption(set_sizeOptions);
      })
      .catch(() => {
        alert("データの削除に失敗しました");
      });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="admin-form">
      <h4>サイズ編集</h4>
      {Object.values(sizeOptions).map((size, index) => {
        return (
          <div className="adminOption-list" key={`info-${index}`}>
            <input
              className="adminOptionListInput"
              value={size.category + "・" + size.gender}
              type="text"
            />
            {size.is_stock ? "在庫あり" : "在庫なし"}
            {errors.name && (
              <span className="text-danger">名前は1文字以上</span>
            )}
            <button
              className="adminOptionListBtn  --delete"
              onClick={(e) => handleDelete(e, size.select_id, size.id)}
            >
              削除
            </button>

            <button
              className="adminOptionListBtn  --update"
              onClick={(e) => handleUpdateStock(e, size.select_id, size.id)}
            >
              在庫切れにする
            </button>
          </div>
        );
      })}
      <div className="adminOption-list">
        <input
          type="text"
          onChange={(e) => handleChange(e)}
          value={inputText}
          className="adminOptionListInput"
        />
        <select onChange={(e) => handleChangeCheckbox(e)}>
          <option hidden>選択してください</option>
          <option value="女">女性</option>
          <option value="男">男性</option>
          <option value="" selected>
            何もなし
          </option>
        </select>
        <input
          type="submit"
          value="作成"
          className="adminOptionListBtn  --create"
          name="create"
        />
      </div>
    </form>
  );
};

export default SizeEditOption;
