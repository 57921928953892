import * as React from "react";
import PopularItem from "./src/PopularItem";
import Item from "./src/Item";
import NoStockItem from "./src/NoStockItem";

interface Props {
  items: [];
  tagName: String;
}

const ItemList: React.FC<Props> = ({ items, tagName }) => {
  const DisplayItem = ({ item, tagName }) => {
    if (!item.stock) {
      return <NoStockItem item={item} tagName={tagName} />;
    } else if (item.ranking > 0) {
      return <PopularItem item={item} tagName={tagName} />;
    } else {
      return <Item item={item} tagName={tagName} />;
    }
  };

  return (
    <ul className="product-list">
      {Object.values(items).map((item) => {
        return (
          <li className="product-item">
            <DisplayItem item={item} tagName={tagName} />
          </li>
        );
      })}
    </ul>
  );
};

export default ItemList;
