import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useItemNameReplace } from "../hocks/useItemNameReplace";

interface Props {
  item: ItemType;
  tagName: String;
}

interface ItemType {
  id: number;
  name: string;
  imageName_1: string;
  price_multiple: number;
  price_single: number;
  ranking: number;
  display: number;
  sub_category: { id: number; name: string };
}

const PopularItem: React.FC<Props> = ({ item, tagName }) => {
  const name = useItemNameReplace(item.name, tagName);

  return (
    <a href={`/item/${item.id}`} className="product-item-block">
      <div className="productImage">
        <LazyLoadImage
          src={`${item.imageName_1}`}
          className="productImage-inner"
        />
        <div className="product-flag">人気</div>
        <div className="discount-block">SALE</div>
      </div>
      <div className="productDetail-block">
        <p className="productPrice">
          ¥{item.price_multiple.toLocaleString()}
          <span className="yen"></span>
          <span className="yen before"> ¥{item.price_single}(割引前)</span>
        </p>
        <h3 className="productName">{name}</h3>
      </div>
    </a>
  );
};

export default PopularItem;
