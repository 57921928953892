import * as React from "react";
import { api } from "../../utils/axios";
import { useForm } from "react-hook-form";
import * as colors from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { ThemeProvider } from "@material-ui/styles";
import { BrandType } from "../../types/models/Brand";

interface Props {
  brands: BrandType[];
}

const Brand: React.FC<Props> = ({ brands }) => {
  const {
    formState: { errors },
  } = useForm();
  const [allBrand, setBrand] = React.useState(brands);
  const theme = createTheme({
    palette: {
      secondary: {
        main: colors.green[700],
      },
    },
  });

  return (
    <div className="form">
      <h4>ブランド一覧</h4>

      <ThemeProvider theme={theme}>
        <MaterialTable
          options={{
            showTitle: false,
            selection: true, // ★ 行選択可能
            headerSelectionProps: {
              color: "primary",
            },
          }}
          columns={[
            {
              title: "ブランドid",
              field: "id",
              editable: "never",
              headerStyle: {
                minWidth: 50,
                maxHeight: 50,
              },
            },
            {
              title: "名前",
              field: "english_name",
              headerStyle: {
                minWidth: 50,
                maxHeight: 50,
              },
            },
            {
              title: "商品画像",
              field: "clothes_image_url",
              headerStyle: {
                maxWidth: 50,
                maxHeight: 50,
              },
            },
            {
              title: "アイキャッチ画像",
              field: "eyecatch_image_url",
              headerStyle: {
                minWidth: 150,
                maxHeight: 50,
              },
            },
            {
              title: "SEOメタ-タイトル",
              field: "seo_meta_title",
              headerStyle: {
                minWidth: 300,
                maxHeight: 50,
              },
            },
            {
              title: "SEOメタ-デスクリプション",
              field: "seo_meta_description",
              headerStyle: {
                minWidth: 450,
                maxHeight: 50,
              },
            },
            {
              title: "英語表記名",
              field: "english_name",
              headerStyle: {
                minWidth: 150,
                maxHeight: 50,
              },
            },
            {
              title: "SEOテキスト",
              field: "seo_text",
              headerStyle: {
                minWidth: 400,
                maxHeight: 50,
              },
            },
            {
              title: "パス",
              field: "path_name",
              headerStyle: {
                minWidth: 150,
                maxHeight: 50,
              },
            },
            {
              title: "作成日時",
              field: "created_at",
              editable: "never",
              headerStyle: {
                minWidth: 150,
                maxHeight: 50,
              },
            },
            {
              title: "更新日時",
              field: "updated_at",
              editable: "never",
              headerStyle: {
                minWidth: 150,
                maxHeight: 50,
              },
            },
          ]}
          data={allBrand}
          editable={{
            onRowUpdate: (newData, oldData) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  api
                    .patch(`/admin/brand/${oldData.id}`, {
                      newData,
                    })
                    .then((res) => {
                      setBrand((prevState) =>
                        prevState.map((obj) =>
                          obj.id === res.data.brand.id ? res.data.brand : obj
                        )
                      );
                    })
                    .catch(() => {
                      alert("データの更新に失敗しました");
                    });
                  resolve("成功しました");
                }, 1000);
              });
            },
          }}
        ></MaterialTable>
        <p></p>
      </ThemeProvider>
    </div>
  );
};

export default Brand;
