import * as React from "react";
import ColorEditOption from "./Admin/ColorEditOption";
import SizeEditOption from "./Admin/SizeEditOption";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface OptionProps {
  colorOption: ColorType[];
  sizeOption: SizeType[];
  item: { id: number; name: string; image: string };
}

interface ColorType {
  id: number;
  select_id: number;
  name: string;
}

interface SizeType {
  id: number;
  select_id: number;
  category: string;
  gender: string;
}

const AdminItemOption: React.FC<OptionProps> = ({
  colorOption,
  sizeOption,
  item,
}) => {
  return (
    <div className="admin-wrapper">
      <h4>商品名: {item.name}のオプション編集画面</h4>
      <LazyLoadImage src={`${item.image}`} className="productImage-inner" />
      <ColorEditOption item={item} colorOption={colorOption} />
      <SizeEditOption item={item} sizeOption={sizeOption} />
    </div>
  );
};

export default AdminItemOption;
