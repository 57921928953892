import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  subCategories: SubCategoryType[];
}

interface SubCategoryType {
  id: number;
  name: string;
  path_name: string;
}

const AccesaryList: React.FC<Props> = ({ subCategories }) => {
  return (
    <div className="categorySp-container -accessory">
      <div className="categorySlider-container">
        <div className="category-block test-category-block">
          <ul className="category-list">
            {Object.values(subCategories).map((subCategory) => {
              return (
                <li className="categoryItem">
                  <a
                    href={`/search/${subCategory.id}`}
                    className="categoryLinkName"
                  >
                    <LazyLoadImage
                      src={`/category/${subCategory.path_name}.jpg`}
                      className="categoryListImage"
                    />
                    <p>{subCategory.name}</p>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccesaryList;
