import * as React from "react";
import { api } from "../utils/axios";

interface questionType {
  question: string;
  options: string[];
}

interface DiagnosisProps {
  clothCategory: string[];
  accesoryCategory: string[];
  questions: questionType[];
}

const Disgnosis: React.FC<DiagnosisProps> = ({
  clothCategory,
  accesoryCategory,
  questions,
}) => {
  const [answers, setAnswers] = React.useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
  });
  const [count, setCount] = React.useState(0);
  const [question, setQuestion] = React.useState<questionType>(
    questions[count]
  );
  const [items, setItems] = React.useState([]);
  const [empty, setEmpty] = React.useState(false);

  const nextQuetion = async () => {
    if (count === questions.length - 1) {
      const response = await api.get("/diagnosis/search", {
        params: answers,
      });
      setItems(response.data.items);
      setEmpty(response.data.message === "empty");
      return;
    }
    // question1の内容によってセットする質問を変更する
    if (answers.question1.includes("アクセサリー") && count === 0) {
      questions[2].options = accesoryCategory;
      setCount(2);
      setQuestion(questions[2]);
      return;
    } else {
      questions[2].options = clothCategory;
    }
    setCount((prev) => prev + 1);
    setQuestion(questions[count + 1]);
  };

  const back = () => {
    setCount((prev) => prev - 1);
    setQuestion(questions[count - 1]);
    if (answers.question1.includes("アクセサリー")) {
      // 質問2をスキップ
      if (count === 2) {
        setCount(0);
        setQuestion(questions[0]);
      }
      // 質問3にアクセサリーをセット
      if (count === 3) {
        questions[2].options = accesoryCategory;
      }
    }
  };
  return (
    <div>
      {items.length === 0 ? (
        <div className="diagnosis-container">
          <p className="question-text">{question.question}</p>
          {question.options.map((option) => {
            return (
              <div className="option-container" key={option}>
                <label>
                  <input
                    type="radio"
                    name={`question${count + 1}`}
                    value={option}
                    onClick={(e: any) =>
                      setAnswers({
                        ...answers,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {option}
                </label>
              </div>
            );
          })}
          <div className="diagnosis-button-wrapper">
            <button className="diagnosis-button" onClick={() => nextQuetion()}>
              {count == questions.length - 1 ? "回答を送信" : "次の質問へ"}
            </button>
            {count > 0 && (
              <button
                className="diagnosis-button --back"
                onClick={() => back()}
              >
                一つ戻る
              </button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="diagnosis-container --finish">
            <p className="question-text">診断が完了しました！</p>
            {empty ? (
              <>
                <p>該当する条件の商品がありませんでした。</p>
                <p>その他のおすすめ商品はこちらです。</p>
              </>
            ) : (
              <p>あなたにおすすめの商品はこちらになります。</p>
            )}
          </div>
          <div className="productSp-container">
            <div className="product-list original-list">
              {items.map((item) => {
                return (
                  <div key={item.id} className="product-item">
                    <a className="product-item-block" href={`/item/${item.id}`}>
                      <div className="productImage">
                        <img
                          className="productImage-inner"
                          src={`${item.imageName_1}`}
                        />
                      </div>
                      <div className="productDetail-block">
                        <p className="productPrice">
                          ¥{item.price_multiple.toLocaleString()}
                          <span className="yen">（税込）</span>
                        </p>
                        <div className="productName">{item.name}</div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Disgnosis;
