import * as React from "react";

const DiagnosisButton: React.FC = () => {
  return (
    <div className="diagnosis-button-container">
      <div>
        <h2>ペアルック診断</h2>
        <p>質問に答えるだけであなたに最適なお揃いアイテムが見つかる</p>
        <div className="to_more_btn-block">
          <a className="to_more" href="/diagnosis">
            ペアルック診断へ進む
          </a>
        </div>
      </div>
    </div>
  );
};

export default DiagnosisButton;
