import * as React from "react";
import ItemList from "../ItemList";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BrandType } from "../../types/models/Brand";

interface Props {
  items: [];
  brand: BrandType;
  sp: boolean;
}

const BrandList: React.FC<Props> = ({ items, brand, sp }) => {
  return (
    <div className="brand-container">
      <div className={sp ? "productSp-container" : "product-container"}>
        <div className="productListTitle-block -brand">
          <div className="brandImage-block">
            <LazyLoadImage src={brand.logo_img} className="logoImage" />
            <LazyLoadImage
              src={brand.clothes_image_url || brand.eyecatch_image_url}
              className="eyecatchImage"
            />
          </div>
          <h1 className="productListTitle">
            {brand.english_name}（{brand.name}）<br />
            のペアルック一覧
          </h1>
        </div>
        <ItemList items={items} tagName={""} />
      </div>
      <div className="productSp-container" />
    </div>
  );
};

export default BrandList;
