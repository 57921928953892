import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  limited_quantity_items: LimitedQuantityItemType[];
}

interface LimitedQuantityItemType {
  item: ItemType;
  color: ColorType;
  size: SizeType;
  id: number;
  inventory_quantity: number;
  sale_price: number;
}

interface ItemType {
  id: number;
  name: string;
  imageName_1: string;
  price_multiple: number;
  number_options: number;
}

interface ColorType {
  name: string;
}

interface SizeType {
  category: string;
  gender: string;
}

const ItemList: React.FC<Props> = ({ limited_quantity_items }) => {
  const OptionBlock = (item, color, size) => {
    if (item.number_options === 2) {
      return size.gender
        ? `${color.name}・${size.gender}・${size.category}サイズ`
        : `${color.name}・${size.category}サイズ`;
    } else if (item.number_options === 1) {
      return `${color.name}サイズ`;
    } else {
      return `${size.gender}・${size.category}サイズ`;
    }
  };

  return (
    <div className="productSp-container">
      <div className="productListTitle-block">
        <h2>最大70%OFF！セール中の商品一覧</h2>
      </div>
      <ul className="product-list">
        {Object.values(limited_quantity_items).map((limited_quantity_item) => {
          return (
            <li className="product-item">
              <a
                href={`/limited_quantity/item/${limited_quantity_item.id}`}
                className="product-item-block"
              >
                <div className="productImage">
                  <LazyLoadImage
                    src={`${limited_quantity_item.item.imageName_1}`}
                    className="productImage-inner"
                  />
                  <div className="product-flag">
                    残り
                    <span> {limited_quantity_item.inventory_quantity}</span>点
                  </div>
                </div>
                <div className="productDetail-block">
                  <p className="productPrice">
                    ¥{limited_quantity_item.sale_price.toLocaleString()}
                    <span className="yen list-price">
                      ¥
                      {limited_quantity_item.item.price_multiple.toLocaleString()}
                      (定価)
                    </span>
                  </p>
                  <div className="limitedItemOptionBlock">
                    {OptionBlock(
                      limited_quantity_item.item,
                      limited_quantity_item.color,
                      limited_quantity_item.size
                    )}
                  </div>
                  <h3 className="productName">
                    {limited_quantity_item.item.name}
                  </h3>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ItemList;
