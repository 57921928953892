export const useItemNameReplace = (name, tagName) => {
  if (tagName === "リンクコーデ") {
    const newName = name.replace(
      /(ペアルック|シミラールック|双子コーデ)/,
      "リンクコーデ"
    );
    return newName;
  } else if (tagName === "シミラールック") {
    const newName = name.replace(
      /(ペアルック|リンクコーデ|双子コーデ)/,
      "シミラールック"
    );
    return newName;
  } else if (tagName === "双子コーデ") {
    const newName = name.replace(
      /(ペアルック|シミラールック|リンクコーデ)/,
      "双子コーデ"
    );
    return newName;
  } else {
    const newName = name;
    return newName;
  }
};
